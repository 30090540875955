import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import NotificationSystemV2 from '../components/DesignSystem/NotificationSystemV2';

/**
 * Displays a notification alert and tracks an event when an attempt is made to edit a schedule activity.
 *
 * @param {string} message - The message to be displayed in the notification.
 * @param {string} activityId - The ID of the activity being edited.
 * @param {string} columnName - The name of the column being edited.
 * @param {string} alerType - The type of alert to be displayed.
 */
export const showGanttAlert = (message, activityId, columnName, alerType) => {
  NotificationSystemV2({
    key: `info-notif-${Date.now()}`,
    type: 'info',
    message
  });
  trackingEvent(
    'Schedule_Activities_Edit_Attempt_Alert',
    {
      ...getBasicAmplitudEventProperties(),
      field_attempted: columnName,
      activity_id: activityId,
      alert_type: alerType
    },
    AMPLITUDE_SERVICE
  );
};
