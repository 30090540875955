/**
 * Manages timeouts and intervals. Use TimerManagerSingleton instead of importing directly.
 */
class TimerManager {
  constructor() {
    /**
     * @type {Map<string, { id: number, type: 'timeout' | 'interval' }>}
     */
    this.timers = new Map();
    this._counter = 0;
  }

  /**
   * Registers a timer with an auto-incrementing unique name.
   * @param {string} name - Base name.
   * @param {number} timerId - The timer ID from setTimeout or setInterval.
   * @param {'timeout'|'interval'} [type='timeout'] - The timer type.
   * @returns {string} Unique timer name.
   */
  registerTimer(name, timerId, type = 'timeout') {
    const uniqueName = `${name}-${++this._counter}`;
    this.timers.set(uniqueName, { id: timerId, type });
    return uniqueName;
  }

  /**
   * Clears a registered timer by its unique name.
   * @param {string} name - Unique timer name.
   */
  clearTimer(name) {
    if (this.timers.has(name)) {
      const { id, type } = this.timers.get(name);
      if (type === 'interval') {
        clearInterval(id);
      } else {
        clearTimeout(id);
      }
      this.timers.delete(name);
    }
  }

  /**
   * Clears all registered timers.
   */
  clearAllTimers() {
    for (const { id, type } of this.timers.values()) {
      if (type === 'interval') {
        clearInterval(id);
      } else {
        clearTimeout(id);
      }
    }
    this.timers.clear();
  }

  /**
   * Creates a setTimeout that removes itself from the registry after execution.
   * @param {Function} callback - Function to execute.
   * @param {number} ms - Delay in milliseconds.
   * @param {string} [baseName='autoTimeout'] - Base name.
   * @returns {string} Unique timer name.
   */
  registerAutoTimeout(callback, ms, baseName = 'autoTimeout') {
    const uniqueName = `${baseName}-${++this._counter}`;
    const wrappedCallback = () => {
      try {
        callback();
      } finally {
        this.clearTimer(uniqueName);
      }
    };
    const timerId = setTimeout(wrappedCallback, ms);
    this.timers.set(uniqueName, { id: timerId, type: 'timeout' });
    return uniqueName;
  }

  /**
   * Registers a normal setInterval.
   * @param {Function} callback - Function to execute periodically.
   * @param {number} ms - Interval in milliseconds.
   * @param {string} [baseName='interval'] - Base name.
   * @returns {string} Unique timer name.
   */
  setInterval(callback, ms, baseName = 'interval') {
    const uniqueName = `${baseName}-${++this._counter}`;
    const timerId = setInterval(callback, ms);
    this.timers.set(uniqueName, { id: timerId, type: 'interval' });
    return uniqueName;
  }
}

let instance = null;

/**
 * Singleton for a single TimerManager instance.
 */
export class TimerManagerSingleton {
  /**
   * @returns {TimerManager} The singleton instance.
   */
  static getInstance() {
    if (!instance) {
      instance = new TimerManager();
    }
    return instance;
  }
}

if (typeof window !== 'undefined') {
  window.TimerManagerSingleton = TimerManagerSingleton;
}
