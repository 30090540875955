import { calendarService } from '../services';

/**
 * Retrieves the calendar for the selected sector in the project state.
 *
 * @param {Object} params - The function parameters.
 * @param {Object} params.projectState - The project state.
 * @param {string} params.projectState.sectorSelected - The selected sector.
 *
 * @returns {Promise<Object>} A promise that resolves to the calendar for the selected sector.
 */
export const getCalendarsForProjectState = async (projectState) => {
  const { calendar } = await calendarService.showBySector(
    projectState.sectorSelected
  );
  return calendar;
};
