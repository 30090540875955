import axios from 'axios';
import { base } from '../services/base';

const AUTH_HEADER_NAME = 'WWW-Authenticate';

export const createHttpClient = (options = {}) => {
  const { headers: baseHeaders, ...extraOptions } = options;
  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'Basic cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=',
    ...baseHeaders
  };

  const httpClient = axios.create({
    baseURL: base.api,
    headers,
    ...extraOptions
  });

  httpClient.interceptors.request.use((config) => {
    if (!config.headers[AUTH_HEADER_NAME]) {
      const sessionToken = sessionStorage.getItem('sessionToken');
      if (sessionToken) {
        config.headers[AUTH_HEADER_NAME] = `Bearer ${sessionToken}`;
      }
    }
    return config;
  });

  return httpClient;
};

export const getFreeTrialHeaders = (data) => {
  const loggedUser = localStorage.getItem('authToken');

  if (!data?.isFreeTrial || !loggedUser) {
    return {};
  }

  return { [AUTH_HEADER_NAME]: `Bearer ${loggedUser}` };
};
