export function lookaheadOnRender(
  item,
  rowNode,
  view,
  columns,
  gantt,
  mountedElements
) {
  for (let i = 0; i < columns.length; i++) {
    let column = columns[i];

    if (column.onrender) {
      // Find the cell node for the current column
      const cellNode = rowNode.querySelector(
        `[data-column-name=${column.name}]`
      );

      if (cellNode) {
        const content = column.onrender(item, cellNode);

        if (content && typeof content === 'object') {
          // Render object to node using React
          if (gantt.config.external_render) {
            const adapter = gantt.config.external_render;

            // Check if an element is already mounted for this item.id + "-" + column.name
            const mounted = mountedElements.get(`${item.id}-${column.name}`);

            if (mounted) {
              // Defer unmounting to avoid React rendering conflicts
              if (mounted.unmountTimeout) {
                clearTimeout(mounted.unmountTimeout);
              }

              mounted.unmountTimeout = setTimeout(() => {
                adapter.unmountElement(mounted.cellNode);
                delete mounted.unmountTimeout;
              }, 0);
            }

            if (adapter.isElement(content)) {
              // Mount the new element and update the reference map
              adapter.renderElement(
                content,
                cellNode,
                null,
                null,
                `${item.id}-${column.name}`
              );
              mountedElements.set(`${item.id}-${column.name}`, {
                content,
                cellNode
              });
            }
          }
        }
      }
    }
  }
}
