import {
  MODULE_ACTION_PERMISSIONS_BY_ROLE,
  GANTT_COLUMN_PERMISSIONS_BY_ROLE,
  GANTT_COLUMN_DEFAULT_VISIBILITY
} from '../constants/permissions';
import { getSessionTokenData } from './userUtils';
import { roleTypes } from '../enums/Role.enum';

export const getPermissionsByCommand = (
  command,
  module = 'MASTERPLAN',
  section = 'GANTT'
) => {
  const userRole = getUserRole();
  if (!userRole) {
    return true;
  }
  const permissions = MODULE_ACTION_PERMISSIONS_BY_ROLE[module][section];
  if (!permissions[command]) {
    return true;
  }
  return permissions[command][userRole];
};

export const isColumnAvailableForRole = (column) => {
  const userRole = getUserRole();
  if (!userRole) {
    return true;
  }
  const columnPermissions = GANTT_COLUMN_PERMISSIONS_BY_ROLE[column];
  if (!columnPermissions) {
    return false;
  }
  return columnPermissions[userRole];
};

export const isColumnVisibleByDefault = (column) => {
  const userRole = getUserRole();
  if (!userRole) {
    return true;
  }
  const columnVisibility = GANTT_COLUMN_DEFAULT_VISIBILITY[column];
  if (!columnVisibility) {
    return false;
  }
  return columnVisibility[userRole];
};

export const getUserRole = () => {
  const sessionTokenData = getSessionTokenData();
  if (!sessionTokenData) {
    return null;
  }
  return getRoleKey(sessionTokenData?.role);
};

const getRoleKey = (value) =>
  Object.keys(roleTypes).find((key) => roleTypes[key] === value);
