/**
 * An enumeration that maps relation types to their corresponding numbers.
 * This object is frozen to make it immutable, ensuring that the mappings
 * cannot be accidentally modified elsewhere in the code.
 *
 * @readonly
 * @enum {string}
 */
export const RelationTypeToNumber = Object.freeze({
  fs: '0',
  ss: '1',
  ff: '2',
  sf: '3'
});

/**
 * Function to construct a graph from the links
 * @param {Array} links - The links to construct the graph from
 * @returns {object} - The constructed graph
 */
export const constructGraph = (links) =>
  links.reduce((acc, link) => {
    if (!acc[link.source]) acc[link.source] = [];
    if (!acc[link.target]) acc[link.target] = [];
    switch (link.type) {
      case RelationTypeToNumber.fs:
      case RelationTypeToNumber.sf:
      case RelationTypeToNumber.ss:
      case RelationTypeToNumber.ff:
        acc[link.source].push(link.target);
        break;
      default:
        throw new Error(`Unsupported link type: ${link.type}`);
    }
    return acc;
  }, {});
/**
 * Function to implement DFS to detect cycles
 * @param {object} graph - The graph to search
 * @returns {Function} - The DFS function
 */
const createDFS = (graph) => {
  const visited = {};
  const stack = {};
  return function dfs(node) {
    if (stack[node]) {
      return true;
    }
    if (visited[node]) return false;
    visited[node] = true;
    stack[node] = true;
    for (const neighbor of graph[node] || []) {
      if (dfs(neighbor)) return true;
    }
    stack[node] = false;
    return false;
  };
};

/**
 * Function to check if a link creates a circular dependency in the task graph.
 * @param {object} link - The link to check.
 * @returns {boolean} - Whether the link creates a circular dependency.
 */
export const isCircularLinkExtended = (link, gantt) => {
  gantt.maxPerformance = false;
  const links = [...gantt.getLinks(), link];
  const graph = constructGraph(links);
  const dfs = createDFS(graph);
  return Object.keys(graph).some(dfs);
};
