import {
  PARTIAL_PERMISSION_PER_MODULE,
  PARTIAL_PERMISSION_PER_COLUMN
} from '../constants/permissions';
import { getSessionTokenData } from './userUtils';
import { roleTypes } from '../enums/Role.enum';

export const getPermissionsByCommand = (
  command,
  module = 'MASTERPLAN',
  section = 'GANTT'
) => {
  const userRole = getUserRole();
  if (!userRole) {
    return true;
  }
  const permissions = PARTIAL_PERMISSION_PER_MODULE[module][section];
  if (!permissions[command]) {
    return true;
  }
  return permissions[command][userRole];
};

export const isColumnAvailableForRole = (column) => {
  const userRole = getUserRole();
  if (!userRole) {
    return true;
  }
  const columnPermissions = PARTIAL_PERMISSION_PER_COLUMN[column];
  if (!columnPermissions) {
    return true;
  }
  return columnPermissions[userRole];
};

const getRoleKey = (value) => {
  return Object.keys(roleTypes).find((key) => roleTypes[key] === value);
};

export const getUserRole = () => {
  const sessionTokenData = getSessionTokenData();
  if (!sessionTokenData) {
    return null;
  }
  return getRoleKey(sessionTokenData?.role);
};
