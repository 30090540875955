import { getUserStatusSettings, getSessionTokenData } from './userUtils';
import { formatDateToText } from './dateUtils';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import {
  EVENT_SOURCE,
  EVENT_SOURCE_URL_MAP
} from '../components/ModalAddUsers/constants';

export const mappedUserToDataSource = (user, t) => {
  const userLastActive = user?.last_active || user?.createdAt;
  const language = t('lang') === 'es' ? 'es' : 'en';
  return {
    ...user,
    avatar: user?.image,
    surname: user?.lastname,
    position: user?.position || '',
    subcontractId: user?.subcontract,
    status: getUserStatusSettings({
      t,
      settings: 'project_settings',
      status: user?.status
    }),
    lastActive: formatDateToText({
      date: userLastActive,
      language
    }),
    action: user?.is_active
  };
};

export const getEventSource = () => {
  const currentUrl = window.location.href.toLowerCase();
  const eventSource = Object.entries(EVENT_SOURCE_URL_MAP)
    .find(([key, urlPart]) => currentUrl.includes(urlPart))
    ?.at(0);

  return eventSource || EVENT_SOURCE.companySettings;
};

export const trackingEventAddUsersButton = () => {
  const userData = getSessionTokenData();
  trackingEvent(
    'add_users_button_clicked',
    {
      ...getBasicAmplitudEventProperties(),
      user_role: userData.role,
      event_source: getEventSource()
    },
    AMPLITUDE_SERVICE
  );
};
