/* eslint-disable no-eval */
import { gantt } from '../assets/gantt/dhtmlxgantt';

class GanttAPI {
  constructor() {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    this.gantt = gantt;
    this.gantt.plugins({
      auto_scheduling: true
    });
    this.gantt.config.work_time = true;
    this.gantt.config.correct_work_time = true;
    this.gantt.config.details_on_create = false;
    this.gantt.config.show_errors = false;
    this.gantt.config.date_format = '%Y-%m-%d %H:%i';
    this.gantt.config.duration_unit = 'hour';
    this.gantt.defaultCalendar = 'global';
    this.defaultCalendar = 'global';
    this.formatter = this.gantt.ext.formatters.durationFormatter({
      enter: 'day',
      store: 'hour',
      format: 'day',
      hoursPerDay: currentSector ? currentSector.hoursPerDay : null,
      hoursPerWeek: currentSector ? currentSector.hoursPerWeek : null,
      short: false
    });
    this.gantt.init();
  }

  /**
   * This method allows custom API to update it formatter about current sector in localstorage
   */
  updateFormatter() {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    this.formatter = this.gantt.ext.formatters.durationFormatter({
      enter: 'day',
      store: 'hour',
      format: 'day',
      hoursPerDay: currentSector.hoursPerDay,
      hoursPerWeek: currentSector.hoursPerWeek,
      short: false
    });
  }

  /**
   * This functions gets a date and calendar for checking if it is workable or not.
   * @param {*} date Date object to check if is workable
   * @param {*} calendar_id calendar ID to get rules for checking workable flow.
   * @returns True if it is workable
   */
  isWorkable(date, calendar_id = this.defaultCalendar) {
    if (!date) return;
    const cal = this.gantt.getCalendar(calendar_id);
    const instancedDate = this.gantt.date.parseDate(date, 'xml_date');
    if (cal) {
      if (instancedDate) {
        // Funcion mal utilizada deberia ser {date, unit: 'day'}
        return cal.isWorkTime({ date: instancedDate, unit: 'day' });
      }
    }
  }

  /**
   * This function takes an number of hours, and through the custom API check the formatter and transforms it to days
   * @param {*} hours Number of hours
   * @returns returns an float number which represents days
   */
  transformHourToDays(hours) {
    /** Hour integration: Duration from component comes in hours, but at BD must be interpreted as days */
    const dayStringFormatDuration = this.formatter.format(hours);
    let durationNumberDaysForDB;
    if (dayStringFormatDuration.includes(' day')) {
      durationNumberDaysForDB = parseFloat(
        dayStringFormatDuration.split(' day')[0]
      );
    } else if (dayStringFormatDuration.includes(' days')) {
      durationNumberDaysForDB = parseFloat(
        dayStringFormatDuration.split(' days')[0]
      );
    }

    return durationNumberDaysForDB;
  }

  /**
   * This takes legacy format calendar from V1 proplanner and transforms it to a new proplanner API
   * @param {*} legacy_working_time Legacy format for v1
   * @returns common 0 and 1 nomenclature to check if a day is or is not a working day
   */
  getWorkingDaysFromCalendar(legacy_working_time) {
    const ganttDhtmlxWorktime = legacy_working_time.map((workingtime) => {
      switch (workingtime) {
        case true:
          return 1;
        case false:
          return 0;
        case 1:
          return 1;
        case 0:
          return 0;
      }
    });

    return ganttDhtmlxWorktime;
  }

  /**
   * This function takes proplanner custom nomenclature DB to create an object whith the shift information
   * @param {*} shift Shift custom Proplanner string
   * @returns an array with gantt dhtmlx component nomenclature for shifts
   */
  transformShiftToArray(shift) {
    const initArray = shift.split('-')[0].split(',');
    const endArray = shift.split('-')[1].split(',');
    const newArrayShift = [];

    initArray.map((el, index) => {
      const newShift = initArray[index] + '-' + endArray[index];
      newArrayShift.push(newShift);
    });

    return newArrayShift;
  }

  /**
   * This function loads calendars to be worked at gantt dhtmlx component
   * @param {*} calendars Array of calendar objects
   * @param {*} otherGanttReference This function reveices any other instance of the gantt dhtmlx, to apply same logic
   */
  loadCalendars(calendars, otherGanttReference = false) {
    // { worktime: [1,0,0 ...], exceptions, id, is_default }

    /** Load all calendars to Gantt DHTMLX to match with calendar from task */
    calendars.map((calendar, index) => {
      const workingDays = calendar.working_days
        .split(',')
        .map((el) => eval(el));
      const shiftsArray = [];
      /** N workingdays integration */
      calendar.shifts
        .sort((a, b) => parseInt(a.correlative_id - parseInt(b.correlative_id)))
        .map((shift) => {
          shiftsArray.push(this.transformShiftToArray(shift.shift_string));
        });

      // const shiftStart = this.transformShiftToArray(calendar.shift_start)
      // const shiftEnd = this.transformShiftToArray(calendar.shift_end)

      /** Load general Gantt worktime (default calendar) */
      const workTime = this.getWorkingDaysFromCalendar(workingDays);

      /** This code section set the default behaviour for TASK without calendar_id */
      if (calendar.is_default && typeof calendar.id !== 'string') {
        this.defaultCalendar = calendar.id;
        this.gantt.defaultCalendar = calendar.id;
        if (otherGanttReference) {
          otherGanttReference.defaultCalendar = calendar.id;
        }
      }

      const singleShiftPerDay = workTime.map((singleWorktime, index) => {
        if (singleWorktime) {
          const toReturnArray = [];
          shiftsArray.map((nShift) => {
            if (nShift[index] != 'false-false') {
              toReturnArray.push(nShift[index]);
            }
          });
          return toReturnArray;
        }
        return 0;
      });

      /** This code adds the calendar to DHTMLX API */
      const calendarToAdd = {
        id: calendar.id,
        worktime: {
          days: singleShiftPerDay
        }
      };
      this.gantt.addCalendar(calendarToAdd);
      if (otherGanttReference) {
        otherGanttReference.addCalendar(calendarToAdd);
      }

      const calendarDhtmlxObject = this.gantt.getCalendar(calendarToAdd.id);
      /** Load all holidays from calendar */
      const holidays = calendar.exceptiondays;
      this.setHolidaysForCalendar(holidays, calendarDhtmlxObject);

      if (otherGanttReference) {
        const calendarDhtmlxObject = otherGanttReference.getCalendar(
          calendarToAdd.id
        );
        /** Load all holidays from calendar */
        const holidays = calendar.exceptiondays;
        this.setHolidaysForCalendar(holidays, calendarDhtmlxObject);
      }
    });
  }

  /**
   * This function calculates duration since an start and end date
   * @param {*} start Date object which represents the start of an activity
   * @param {*} end Date object which represents and end of an activity
   * @param {*} calendar_id Calendar rules for that activity
   * @param {*} gantt Instance of dthmlx gantt to be used for that process
   * @returns returns a number of hours about infromation at params
   */
  calculateDuration(
    start,
    end,
    calendar_id = this.defaultCalendar,
    gantt = this.gantt
  ) {
    const startObject = new Date(start);
    const endObject = new Date(end);
    let inverted = false;
    let result = 0;
    let task;
    if (startObject.getTime() > endObject.getTime()) {
      task = { start_date: endObject, end_date: startObject };
      inverted = true;
    } else {
      task = { start_date: startObject, end_date: endObject };
    }

    const calendar = this.getTaskCalendar(calendar_id, gantt);
    if (!calendar) {
      return 0;
    }
    result = calendar.calculateDuration(task);

    if (inverted) {
      return -result;
    }
    return result;
  }

  /**
   * This function ensure that there is no task without an calendar
   * @param {*} calendar_id calendar id to be used
   * @param {*} gantt Instance of dthmlx gantt to be used for that process
   * @returns return an calendar object from DHTMLX component
   */
  getTaskCalendar(calendar_id, gantt = this.gantt) {
    let calendar;

    if (calendar_id) {
      calendar = calendar_id;
    } else {
      calendar =
        gantt == this.gantt ? this.defaultCalendar : gantt.defaultCalendar;
    }

    calendar = gantt.getCalendar(calendar);

    return calendar;
  }

  /**
   * This function sets holidays for each calendar
   * @param {*} holidays Gives the holiday information array
   * @param {*} calendar gives the calendar object which must be added their holidays
   */
  setHolidaysForCalendar(holidays, calendar) {
    if (calendar) {
      holidays.map((holiday) => {
        /** We cut the ISO format to YY-MM-DD */
        const holidayInitString = holiday.from_date.split('T')[0];
        const holidayEndString = holiday.to_date.split('T')[0];
        /** Parse string dates to JS Date object */
        const holidayInit = this.gantt.date.parseDate(
          holidayInitString,
          'xml_date'
        );
        const holidayEnd = this.gantt.date.parseDate(
          holidayEndString,
          'xml_date'
        );
        // holidayEnd.setDate(holidayEnd.getDate() + 1)

        /**
         * BUG FIX: range of holidays with start and init date were not taking rigth.
         * This modification includes now an simple iteration that plus 1 day to a flag, that
         * starts with init_date from the holiday.
         *
         * This will include cases with range and not range (to avoid writing spaghetti code)
         */
        let currentHoliday = holidayInit;
        if (holidayEnd >= holidayInit) {
          while (true) {
            if (holiday.every_type) {
              let hoursShift = [];
              if (holiday.workable) {
                holiday.shifts
                  .sort((a, b) =>
                    parseInt(a.correlative_id - parseInt(b.correlative_id))
                  )
                  .map((shift) => {
                    if (shift.shift_string != 'false-false') {
                      hoursShift.push(shift.shift_string);
                    }
                  });
              } else {
                hoursShift = false;
              }

              calendar.setWorkTime({ date: currentHoliday, hours: hoursShift });

              if (holiday.every_type == 'YEARLY') {
                currentHoliday = new Date(
                  currentHoliday.setFullYear(currentHoliday.getFullYear() + 1)
                );
                // new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              if (holiday.every_type == 'DAILY') {
                currentHoliday = new Date(
                  currentHoliday.setDate(currentHoliday.getDate() + 1)
                );
              }
              if (holiday.every_type == 'WEEKLY') {
                currentHoliday = new Date(
                  currentHoliday.setDate(currentHoliday.getDate() + 7)
                );
              }
              if (holiday.every_type == 'MONTHLY') {
                currentHoliday = new Date(
                  currentHoliday.setMonth(currentHoliday.getMonth() + 1)
                );
              }
              if (currentHoliday > holidayEnd) break;
            } else {
              /** Define shift by flag workable */
              let hoursShift = [];
              if (holiday.workable) {
                holiday.shifts
                  .sort((a, b) =>
                    parseInt(a.correlative_id - parseInt(b.correlative_id))
                  )
                  .map((shift) => {
                    if (shift.shift_string != 'false-false') {
                      hoursShift.push(shift.shift_string);
                    }
                  });
              } else {
                hoursShift = false;
              }

              calendar.setWorkTime({ date: currentHoliday, hours: hoursShift });

              currentHoliday = new Date(
                currentHoliday.setDate(currentHoliday.getDate() + 1)
              );

              if (currentHoliday > holidayEnd) break;
            }
          }
        }
      });
    }
  }

  /**
   * This function allows to get diff of two dates
   * @param {*} dt2 date object one
   * @param {*} dt1 date object two
   * @returns Difference of this two dates
   */
  diff_years(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  }

  /**
   * This function returns an fitted task by it self calendar, to ensure consistence of data at UI
   * @param {*} task Task to be deupred it dates ranges
   * @returns an correct duration, start and end coherence about inital data given
   */
  getDateReturn(task) {
    const updateTaskTiming = (task) => {
      const calendar = this.getTaskCalendar(task.calendar_id);
      task.start_date = calendar.getClosestWorkTime({
        dir: 'future',
        date: task.start_date,
        unit: this.gantt.config.duration_unit,
        task: task
      });
      task.end_date = calendar.calculateEndDate(task);
    };
    updateTaskTiming(task);
    this.gantt.updateTask(task.id);

    const dayFormatDuration = this.transformHourToDays(task.duration);

    /** Hour integration */
    return {
      duration: dayFormatDuration,
      end_date: task.end_date,
      start_date: task.start_date
    };
  }

  /**
   * This function allows giving end and duration, to use Gantt DHTMLX API methods to calculate
   * with calendars correct values at start_dates
   * @param {*} end plain text date with format YYYY/MM/DD
   * @param {*} duration number with duration value
   * @param {*} id id from lookahead task
   */
  getStartByEnd(end, duration, id) {
    const task = {
      id: id,
      name: 'non-printed',
      end_date: end,
      duration: duration
    };
    this.gantt.createTask(task);
    return this.getDateReturn(task);
  }

  /**
   * This function allows giving start and duration, to use Gantt DHTMLX API methods to calculate
   * with calendars correct values at end_dates
   * @param {*} start plain text date with format YYYY/MM/DD
   * @param {*} duration number with duration value IN DAYS
   * @param {*} id id from lookahead task
   */
  getEndByDuration(start, duration, id, calendar_id = null) {
    this.updateFormatter();
    /** Hour integration */
    const dayFormatted = duration + ' days';
    const hourDuration = this.formatter.parse(dayFormatted);

    const task = {
      id: id,
      name: 'non-printed',
      start_date: start,
      duration: hourDuration
    };
    if (calendar_id) {
      task.calendar_id = calendar_id;
    } else {
      task.calendar_id = this.defaultCalendar;
    }

    this.gantt.createTask(task);
    return this.getDateReturn(task);
  }

  /**
   * This function allows giving start and duration, to use Gantt DHTMLX API methods to calculate
   * with calendars correct values at end_dates
   * @param {*} start plain text date with format YYYY/MM/DD
   * @param {*} duration number with duration value IN HOURS
   * @param {*} id id from lookahead task
   */
  getEndByDurationHours(start, hourDuration, id, calendar_id = null) {
    this.updateFormatter();
    /** Hour integration */
    // const dayFormatted = duration + ' days'
    // const hourDuration = this.formatter.parse(dayFormatted)

    const task = {
      id: id,
      name: 'non-printed',
      start_date: start,
      duration: hourDuration
    };
    if (calendar_id) {
      task.calendar_id = calendar_id;
    } else {
      task.calendar_id = this.defaultCalendar;
    }

    this.gantt.createTask(task);
    return this.getDateReturn(task);
  }
}

/**
 * Custom API for Proplanner used at any other module that is not masterplan module (which has it own gantt dhtmlx version)
 */
export const ganttAPI = new GanttAPI();
